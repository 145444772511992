import * as React from "react"
import SparkleSmallIcon from "../img/sparkle-small.svg"
import { LayoutNoHelmet } from "../components/layout"
import CountUp from "react-countup"
import { graphql } from "gatsby"
import BulletElement from "../widgets/BulletElement"
import StyledCircularButton from "../widgets/StyledCircularButton"
import ArrowDownCurv from "../img/arrow-down-curv.svg"
import LongLandingContactUs from "../widgets/longLandingContactUs"

export default function MainPage(props: any) {
  const { allContentfulCaseStudies } = props.data
  const data = allContentfulCaseStudies.nodes.map((e) => ({
    summary: e.summary,
  }))[0]
  // const p = {
  //   ...props,
  //   title: "India’s Biggest Influencer Marketing Platform",
  //   description:
  //     "Collaborate with 1L+ Nano-Micro-Macro-Mega Influencers on Instagram & YouTube. Top brands like Nykaa, purplle, Lakme, Sugar, HUL, Vero Moda, etc. Use OPA to run Influencer Marketing Campaigns.",
  // }

  return (
    <LayoutNoHelmet>
      <div className="mainContainer">
        <div className="container">
          <section
            className="main-banner hero-banner height-box"
            style={{ justifyContent: "center" }}
          >
            <div className="hero-banner-left hero-banner-left-1">
              <h1>
                <span className="text-highlight-long">Micro-Influencer</span>
                &nbsp;
                <br />
                <span className="text-highlight-long">Marketing</span>&nbsp; at
                Scale
              </h1>
              <p>
                Run campaigns with upto 10k influencers at once. Prices as low
                as Rs. 250 per influencer
              </p>

              <div className="hero-banner-counter-1">
                {data.summary.map((stat, index) => {
                  return (
                    <div
                      className="hero-banner-counter-single"
                      style={{ display: "inline-block" }}
                    >
                      <span>
                        <CountUp
                          start={0}
                          end={parseInt(stat.value)}
                          duration={1}
                          className="counter"
                        />
                        <sub>{stat.sub}</sub>
                      </span>
                      <br />
                      <span
                        style={{ fontSize: "1rem" }}
                        dangerouslySetInnerHTML={{ __html: stat.key }}
                      ></span>
                    </div>
                  )
                })}
              </div>
              <div className="hero-sparkle-4 rellax" data-rellax-speed="2">
                <SparkleSmallIcon />
              </div>
            </div>
            <div
              className="hero-banner-right"
              style={{
                maxWidth: 500,
                minWidth: 340,
                justifyContent: "center",
              }}
              id="contact-form"
            >
              <LongLandingContactUs />
            </div>
          </section>

          <section className="case-studies" style={{ marginTop: "90px" }}>
            <h2 style={{ textAlign: "center" }}>Clientele</h2>
            <div className="case-studies-logo-list">
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client1_bn5uq3.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client2_vrszgk.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client3_vcynvz.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client4_uxtfqa.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client5_zesk7i.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client6_zymcez.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client7_pwpil7.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client8_adafz5.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client9_o1d2ka.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client10_fedmt7.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client11_xedxwg.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client12_qvoprw.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client13_bfhylx.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client14_rlme8s.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client15_vsqfec.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client16_h8yvaa.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client17_ies8vv.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client18_kfr5uc.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client19_yup4g6.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client20_sd7xgf.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client21_sh8j7s.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client22_acsmqb.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client23_re6vnx.png"
                  alt=""
                />
              </div>
              <div className="case-studies-logo-single">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/client24_nftfzj.png"
                  alt=""
                />
              </div>
            </div>
          </section>
          <br />
          <br />
          <br />
          <br />
          <section className="hero-banner discover-box height-box" id="feature">
            <div className="hero-banner-left">
              <h3>
                Discover <br />
                <span className="text-highlight-long">Best Influencers</span>
              </h3>
              <ul>
                <BulletElement
                  title={"No Garbage"}
                  description={"opa manually vets & rates each influencer"}
                />
                <BulletElement
                  title={"Comprehensive Data"}
                  description={
                    "use 30+ metrics like age, kids, profession, city, engagement, etc."
                  }
                />

                <BulletElement
                  title={"Insider Information"}
                  description={
                    "use data from 3lakh+ collabs done by opa to find winners "
                  }
                />
              </ul>
              <StyledCircularButton
                label="Contact Us Now"
                url={`/long-landing/#contact-form`}
                isBig={false}
                isBlack={true}
              />
              <div
                className="discover-graphic rellax rellaxAnim"
                data-rellax-speed="-0.5"
                data-rellax-percentage="0.5"
              >
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/discover-graphic_ahjfsk.png"
                  alt=""
                />
              </div>
            </div>
            <div className="discover-imgs">
              <div className="discover1">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/discover1_mfunos.png"
                  alt="img"
                />
              </div>
              <div
                className="discover2 rellax rellaxAnim"
                data-rellax-speed="2"
                data-rellax-percentage="0.5"
              >
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/discover2_aoabxg.png"
                  alt="img"
                />
              </div>
              <div
                className="discover3 rellax rellaxAnim"
                data-rellax-speed="1"
                data-rellax-percentage="0.5"
              >
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/discover3_qrh3lz.png"
                  alt="img"
                />
              </div>
            </div>
          </section>
          <section className="hero-banner hustleBox height-box">
            <div className="hustle-imgs">
              <div className="hustle1">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/hustle1_fjiqi6.png"
                  alt="img"
                />
              </div>
              <div
                className="hustle2 rellax rellaxAnim"
                data-rellax-speed="-0.5"
              >
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/hustle2_caggcr.png"
                  alt="img"
                />
              </div>
              <div
                className="hassle-diamond-1 rellax rellaxAnim"
                data-rellax-percentage="0.5"
                data-rellax-speed="1"
              >
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/diamond2_ppimiv.png"
                  alt=""
                />
              </div>
              <div
                className="hassle-diamond-2 rellax rellaxAnim"
                data-rellax-percentage="0.5"
                data-rellax-speed="-1"
              >
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/diamond_jwp0xg.png"
                  alt=""
                />
              </div>
            </div>
            <div className="hero-banner-right">
              <h3>
                <span className="text-highlight-long">Hassle-free</span>
                <br />
                Execution
              </h3>
              <ul>
                <BulletElement
                  title={"Orienting Influencers"}
                  description={
                    "Thousands of influencers accept collabs in seconds on our App"
                  }
                />
                <BulletElement
                  title={"Delivering Products"}
                  description={
                    "opa delivers products to influencers, handles returns, coordination, etc."
                  }
                />
                <BulletElement
                  title={"Evaluating Content"}
                  description={
                    "opa evaluates every content piece basis your guidelines"
                  }
                />
              </ul>
              <StyledCircularButton
                label="Contact Us Now"
                url={`/long-landing/#contact-form`}
                isBig={false}
                isBlack={true}
              />
              <div
                className="hassle-diamond-3 rellax rellaxAnim"
                data-rellax-percentage="0.5"
                data-rellax-speed="-1"
              ></div>
              <img
                src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/diamond_jwp0xg.png"
                alt=""
              />
            </div>
          </section>
          <section className="hero-banner height-box height-box-reverse">
            <div className="hero-banner-left">
              <h3>
                Detailed
                <br />
                <span className="text-highlight-long">Live Report</span>
              </h3>
              <ul>
                <BulletElement
                  title={"Social Metrics"}
                  description={"measure reach, engagement, etc. in real time"}
                />
                <BulletElement
                  title={"Guidelines Obeyed"}
                  description={
                    "check which guidelines are being followed and working"
                  }
                />
                <BulletElement
                  title={"Sales ROI"}
                  description={
                    "measure link clicks & sales (for shopify & woocommerce)"
                  }
                />
              </ul>
              <StyledCircularButton
                label="Contact Us Now"
                url={`/long-landing/#contact-form`}
                isBlack={true}
              />

              <ArrowDownCurv
                className="live-report-graphic rellax rellaxAnim"
                data-rellax-speed="0.5"
                data-rellax-percentage="0.5"
                alt=""
              />
            </div>
            <div className="live-report-imgs">
              <img
                src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/live-report_xjzbow.png"
                alt="img"
              />
            </div>
          </section>
        </div>
      </div>
      <br />
      <br />
    </LayoutNoHelmet>
  )
}

export const query = graphql`
  {
    allContentfulCaseStudies {
      nodes {
        summary {
          id
          key
          value
          sub
        }
      }
    }
  }
`
